import { MainContainer } from "./style"


function App() {
  return (
    <MainContainer>
      <h1>Aula de React Router</h1>
    </MainContainer>
  )
}

export default App
